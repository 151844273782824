// https://en.wikipedia.org/wiki/MurmurHash
// https://github.com/garycourt/murmurhash-js

function murmurhash2_32_gc(str: string, seed: number) {
  // biome-ignore lint/style/useSingleVarDeclarator: This is a port of the original code
  let l = str.length,
    h = seed ^ l,
    i = 0,
    // biome-ignore lint/suspicious/noImplicitAnyLet: This is a port of the original code
    k;

  while (l >= 4) {
    k =
      (str.charCodeAt(i) & 0xff) |
      ((str.charCodeAt(++i) & 0xff) << 8) |
      ((str.charCodeAt(++i) & 0xff) << 16) |
      ((str.charCodeAt(++i) & 0xff) << 24);

    k = (k & 0xffff) * 0x5bd1e995 + ((((k >>> 16) * 0x5bd1e995) & 0xffff) << 16);
    k ^= k >>> 24;
    k = (k & 0xffff) * 0x5bd1e995 + ((((k >>> 16) * 0x5bd1e995) & 0xffff) << 16);

    h = ((h & 0xffff) * 0x5bd1e995 + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16)) ^ k;

    l -= 4;
    ++i;
  }

  switch (l) {
    case 3:
      h ^= (str.charCodeAt(i + 2) & 0xff) << 16;
      return;
    case 2:
      h ^= (str.charCodeAt(i + 1) & 0xff) << 8;
      return;
    case 1:
      h ^= str.charCodeAt(i) & 0xff;
      h = (h & 0xffff) * 0x5bd1e995 + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16);
  }

  h ^= h >>> 13;
  h = (h & 0xffff) * 0x5bd1e995 + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16);
  h ^= h >>> 15;

  return h >>> 0;
}

export function getBucket(str: string, buckets: number) {
  const hash = murmurhash2_32_gc(str, 0x9747b28c);
  const bucket = (hash ? hash : 0) % buckets;
  return bucket;
}
