import { UserContext } from "@components/user-context/user-context";
import { parseTallyConfig, isFormEnabled } from "@lib/tally/hooks-utils";
import { useNewMeasurements } from "@lib/tally/useNewMeasurements";
import { useNps } from "@lib/tally/useNps";
import { randomItem } from "@utils/array-helpers";
import { getBucket } from "@utils/hash-into-cohorts";
import { type OpCo, useBrand } from "@utils/use-brand";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { useUserPreferences } from "@utils/use-user-preferences";
import { add, isAfter } from "date-fns";
import { useRouter } from "next/router";
import { useContext, useMemo } from "react";
import * as z from "zod";

const BUCKET_SIZE = 6;

function showNpsTallyStrategyPerOpco({ opCo, userId }: { opCo: OpCo; userId: string }) {
  const month = new Date().getMonth(); // month is also 0 based

  // Note: wee *should avoid* if opCo logic, but dealing w/ this
  // via configcat or other "static config" is not worth it
  // for now. We can revisit this later.
  // Moreover, liber has a way lower user base, so the strategy needs to be different.
  // When we will get users' data dynamically, we can remove this.
  // Context here https://github.com/infinitaslearning/issues-pep/issues/470#issuecomment-1715405645
  if (opCo === "liber") {
    return month === 0 || month === 5;
  }

  // We want to ask the user twice a year. That's why we assign the user
  // via a hashing algorithm to a bucket of 0 through 5.
  const bucket = getBucket(userId, BUCKET_SIZE); // the buckets are 0 based

  return month === bucket || month === bucket + BUCKET_SIZE;
}

// A similar strategy to the NPS but "shifted" by 3 months
function showNewMeasurementsTallyStrategyPerOpco({ opCo, userId }: { opCo: OpCo; userId: string }) {
  if (opCo !== "noordhoff") return false;

  const month = new Date().getMonth(); // month is also 0 based

  // We want to ask the user twice a year. That's why we assign the user
  // via a hashing algorithm to a bucket of 0 through 5.
  const bucket = getBucket(userId, BUCKET_SIZE); // the buckets are 0 based

  // Offset by 3 months so we fall inbetween the NPS
  return month === bucket + BUCKET_SIZE / 2 || month === bucket + BUCKET_SIZE + BUCKET_SIZE / 2;
}

const zodTallyShowAfterDate = z.record(z.string(), z.string());
export type TallyShowAfterDate = z.infer<typeof zodTallyShowAfterDate>;

const zodTallyShownInfo = z.record(
  z.string(),
  z.object({
    shownOn: z.string(),
    formId: z.string(),
  }),
);
export type TallyShownInfo = z.infer<typeof zodTallyShownInfo>;

// Displays a tally form
// If multiple tally forms are configured to be shown, it will show the one with the highest priority only
export const useTallyForm = () => {
  const router = useRouter();
  const isHomepage = router.route === "/";
  const isInClass = router.asPath.split("?")[0].endsWith("/inclass");

  const userContext = useContext(UserContext);
  const userId = userContext?.me.data?.id;

  const tallyFormConfigString = useFeatureFlag<string>("newTallyFormsConfig", "null");
  const tallyFormConfig = useMemo(
    () => parseTallyConfig(tallyFormConfigString),
    [tallyFormConfigString],
  );

  const npsFormId = useFeatureFlag<string>("tallyFormId", "");
  const { locale, opCo } = useBrand();
  const [language] = locale.split("-");

  const { preferences, setPreferences, isPreferencesLoading } = useUserPreferences();

  const showAfterDate: TallyShowAfterDate | undefined = useMemo(
    () => zodTallyShowAfterDate.safeParse(preferences?.tallyShowAfterDate).data,
    [preferences],
  );
  const setShowAfterDate = useMemo(
    () => (update: (showAfterDate: TallyShowAfterDate | undefined) => TallyShowAfterDate) => {
      setPreferences({ tallyShowAfterDate: update(showAfterDate) });
    },
    [showAfterDate, setPreferences],
  );

  const tallyShownInfo: TallyShownInfo | undefined = useMemo(
    () => zodTallyShownInfo.safeParse(preferences?.tallyShownInfo).data,
    [preferences],
  );
  const setTallyShownInfo = useMemo(
    () => (update: (tallyShownInfo: TallyShownInfo | undefined) => TallyShownInfo) =>
      setPreferences({ tallyShownInfo: update(tallyShownInfo) }),
    [tallyShownInfo, setPreferences],
  );

  const showNps = useMemo(() => {
    const correctContext = !!isHomepage && !!npsFormId && !!userId;
    const enabledForOpco = !!userId && showNpsTallyStrategyPerOpco({ opCo, userId });

    const userShowAfterDate = userId && showAfterDate && showAfterDate[userId];
    const showAgain = userShowAfterDate ? isAfter(new Date(), new Date(userShowAfterDate)) : true;

    return correctContext && enabledForOpco && showAgain;
  }, [isHomepage, opCo, npsFormId, userId, showAfterDate]);

  const showNewMeasurements = useMemo(() => {
    const correctContext = !isInClass && !!tallyFormConfig && !!userId;
    const enabledForOpco = !!userId && showNewMeasurementsTallyStrategyPerOpco({ opCo, userId });

    const shownOn = userId && tallyShownInfo?.[userId]?.shownOn;
    const userShowAfterDate = shownOn && add(new Date(shownOn), { months: 1 });
    const showAgain = userShowAfterDate ? isAfter(new Date(), new Date(userShowAfterDate)) : true;

    const enabledFromConfig = isFormEnabled(tallyFormConfig);

    return correctContext && enabledForOpco && showAgain && enabledFromConfig;
  }, [isInClass, opCo, tallyFormConfig, userId, tallyShownInfo]);

  const newMeasurementsFormToShow = useMemo(() => {
    // If preferences is still loading or we don't have a userId, do nothing yet
    if (isPreferencesLoading || !userId || !tallyFormConfig?.formId) return;

    // If prefrences have been loaded, fetch the previously shown formId
    const formId = userId && tallyShownInfo?.[userId]?.formId;

    // If there is no previously shown formId this means we are showing the form for the first time. Show a random form.
    if (!formId) {
      return randomItem(tallyFormConfig.formId);
    }

    // Otherwise we select the next form from the config
    // A circular array would probably be useful here, but it seems overkill for only this one time use
    const position = tallyFormConfig.formId.indexOf(formId);
    return position === tallyFormConfig.formId.length - 1
      ? tallyFormConfig.formId[0]
      : tallyFormConfig.formId[position + 1];
  }, [isPreferencesLoading, tallyFormConfig, userId, tallyShownInfo]);

  useNps({
    enabled: showNps,
    formId: npsFormId,
    userId,
    organizationId: userContext?.me.data?.organizationId || "",
    language,
    setShowAfterDate,
  });

  useNewMeasurements({
    enabled: showNewMeasurements,
    formId: newMeasurementsFormToShow,
    userId,
    organizationId: userContext?.me.data?.organizationId || "",
    language,
    setTallyShownInfo,
  });
};
