import { isWeekend, isWithinInterval, setHours, setMinutes } from "date-fns";

import { z } from "zod";
import { stringToJSONSchema } from "@lib/zod/zod-utils";
import { getApmWeb } from "@lib/observability";

const TIME_REGEX = /^\d{2}:\d{2}$/;
const ERROR_LOCATION = "hook-tally";

export const TallyFormConfigSchema = z
  .object({
    formId: z.array(z.string()),
    disabledOn: z.array(z.array(z.string().regex(TIME_REGEX))),
    disabledOnWeekends: z.array(z.array(z.string().regex(TIME_REGEX))),
    key: z.string(),
  })
  .nullable();

export type TallyFormConfig = z.infer<typeof TallyFormConfigSchema>;

export const parseTallyConfig = (configString: string) => {
  const logError = <T>(error: z.ZodError<T>) => {
    getApmWeb().captureError(
      new Error(
        `Error parsing Tally form config (string to json): ${configString}. ${error.message}`,
      ),
      ERROR_LOCATION,
    );
  };

  const obj = stringToJSONSchema.safeParse(configString);
  if (obj.error) {
    logError(obj.error);
    return null;
  }
  const config = TallyFormConfigSchema.safeParse(obj.data);
  if (config.error) {
    logError(config.error);
    return null;
  }
  return config.data;
};

export const hasClashes = (now: Date, disabledOn: Array<string[]>) =>
  disabledOn.some(([start, end]) => {
    if (!TIME_REGEX.test(start) || !TIME_REGEX.test(end)) {
      return false;
    }

    const [startHour, startMinutes] = start.split(":");
    const [endHour, endMinutes] = end.split(":");
    const startTime = setMinutes(setHours(now, Number(startHour)), Number(startMinutes));
    const endTime = setMinutes(setHours(now, Number(endHour)), Number(endMinutes));

    return isWithinInterval(now, { start: startTime, end: endTime });
  });

// returns false if:
//  - we're in week days and the current timestamp is within disabledOn
//    - or we're in weekends and the current timestamp is within disabledOnWeekends
export const isFormEnabled = (formConfig: TallyFormConfig, now = new Date()) => {
  if (!formConfig) return false;

  const { disabledOn = [], disabledOnWeekends = [] } = formConfig;

  if (isWeekend(now)) {
    return !hasClashes(now, disabledOnWeekends);
  }

  return !hasClashes(now, disabledOn);
};
