export function arrayFrom(length: number, start?: number) {
  return Array.from({ length }, (_, i) => i + (start ?? 0));
}

// Sort an array and return a (shallow) copy (does NOT sort in place)
export function sort<T>(array: T[], sorter?: (a: T, b: T) => number) {
  return [...array].sort(sorter);
}

export function unique<T>(array: T[]) {
  return [...new Set(array)];
}

export function randomItem<T>(array: T[]) {
  return array[Math.floor(Math.random() * array.length)];
}
