import { type GetClassroomsQuery, useGetClassroomsQuery } from "@generated/graphql";
import type { UseQueryOptions } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

export const NO_CLASS_ID = "no-attached-classes";
/*
 * Fetch the classrooms of the user and:
 *    - Redirect the user if he has no classrooms
 *    - Select the first classroom if none is found on the URL
 *    - Check if the user is part of the classroom on the URL,
 *          if not use the first classroom of the user
 */
export const useClassrooms = (
  options?: Omit<UseQueryOptions<GetClassroomsQuery, unknown, GetClassroomsQuery>, "queryKey">,
) => {
  const router = useRouter();
  // this is true only if the URL classroom is valid, it can be used to prevent operations to run with an incorrect id
  const [isClassroomValid, setIsClassroomValid] = useState<boolean | undefined>();
  const classroomId: string | string[] | undefined = router.query.classroomId as string;

  const updateClassroomId = useCallback(
    (data: GetClassroomsQuery, hasValidClassroom: boolean) => {
      const hasNoClassrooms = data.classroomsOfUser.length === 0;

      if (hasNoClassrooms) {
        setIsClassroomValid(false);
      }

      if (!hasValidClassroom) {
        router.replace(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              classroomId: hasNoClassrooms ? NO_CLASS_ID : data.classroomsOfUser?.[0].id,
            },
          },
          undefined,
          { shallow: true },
        );
      }
    },
    [router],
  );

  const queryResult = useGetClassroomsQuery(
    {},
    {
      ...options,
    },
  );

  useEffect(() => {
    const hasValidClassroom =
      !!queryResult.data?.classroomsOfUser.find((classroom) => classroom.id === classroomId) ||
      classroomId === NO_CLASS_ID;

    if (!hasValidClassroom && queryResult.data) {
      updateClassroomId(queryResult.data, hasValidClassroom);
    }

    if (
      !hasValidClassroom &&
      !queryResult.data?.classroomsOfUser.length &&
      !queryResult.isFetching &&
      !queryResult.isLoading
    ) {
      updateClassroomId(
        { classroomsOfUser: [{ id: NO_CLASS_ID, name: "TempId" }] },
        hasValidClassroom,
      );
    }

    if (hasValidClassroom) {
      setIsClassroomValid(true);
    }
  }, [
    classroomId,
    queryResult.data,
    updateClassroomId,
    queryResult.isLoading,
    queryResult.isFetching,
  ]);

  return {
    ...queryResult,
    isLoading: !queryResult.error ? !isClassroomValid || queryResult.isLoading : false,
  };
};
