const ALPHA_WAVE_ROLES = ["PEP.ALPHAWAVE"];
const PUBLISHER_ROLES = ["PEP.ALPHAWAVE", "PUBLISHER"];
const ICT_COORDINATOR_ROLES = ["ICTCOORDINATOR", "AMBRASOFT.ICTCOORDINATOR"];
const TEACHER_ROLES = ["TEACHERS", "EXTRA_TEACHERS", "AMBRASOFT.TEACHER"];

type Roles = "teacher" | "publisher" | "alpha-wave" | "ict-coordinator";

const getRolesByType = (role: Roles) => {
  switch (role) {
    case "teacher":
      return TEACHER_ROLES;
    case "publisher":
      return PUBLISHER_ROLES;
    case "alpha-wave":
      return ALPHA_WAVE_ROLES;
    case "ict-coordinator":
      return ICT_COORDINATOR_ROLES;
  }
};

export const isUserInRole = (roleToFind: Roles, roles?: string[]) => {
  if (!roles) return false;
  const rolesStrings = getRolesByType(roleToFind);

  const upperCaseRoles = roles.map((r) => r.toUpperCase());
  return rolesStrings.filter((r) => upperCaseRoles.includes(r)).length > 0;
};

export const isUserOnlyICTCoordinator = (userRoles: string[]) => {
  const isUserICTCoordinator = isUserInRole("ict-coordinator", userRoles);
  const isTeacher = isUserInRole("teacher", userRoles);
  return isUserICTCoordinator && !isTeacher;
};
