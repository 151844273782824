import type { Tracker } from "@infinitaslearning/module-data-analyticstracker";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { type FeatureFlag, useFeatureFlags } from "@utils/use-feature-flag";
import { useEffect, useMemo } from "react";

export const ACTIVE_EXPERIMENTS = ["rltDownloadsButtonAB"] as const;

export type ExperimentFeatureFlag = (typeof ACTIVE_EXPERIMENTS)[number] & FeatureFlag;

export type Variant = Parameters<Tracker["experimentStarted"]>[0]["experimentGroup"];

type Experiment = {
  experimentGroup: Variant;
  experimentName: ExperimentFeatureFlag;
};

export const useLoadExperiments = (experiments: readonly ExperimentFeatureFlag[]): Experiment[] => {
  const experimentValues = useFeatureFlags(experiments);

  const result = useMemo(() => {
    // If any of the experiments has the value "undefined" we can assume that not all feature flag values have been loaded yet
    if (!experimentValues || Object.values(experimentValues).some((v) => v === undefined))
      return [];

    return Object.entries(experimentValues).map(([experiment, value]) => {
      return {
        experimentGroup: value ? ("variant" as const) : ("control" as const),
        experimentName: experiment as ExperimentFeatureFlag,
      };
    });
  }, [experimentValues]);

  return result;
};

export const useTrackExperiments = () => {
  const experiments = useLoadExperiments(ACTIVE_EXPERIMENTS);
  const tracker = getAnalyticsTracker();

  useEffect(() => {
    for (const experiment of experiments) {
      tracker?.experimentStarted({
        experimentName: experiment.experimentName,
        experimentGroup: experiment.experimentGroup,
      });
    }
  }, [experiments, tracker]);
};
