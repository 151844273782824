import type { UserContextType } from "./user-context";

/**
 * Return true if the user has a role that *contains* the passed string
 */
export const hasRole = (user: UserContextType, role: string): boolean => {
  if (!user.me.data) {
    return false;
  }

  return user.me.data.roles.find((r) => r.toLowerCase().includes(role.toLowerCase())) !== undefined;
};

export const isUserAlphaOrBeta = (user: UserContextType): boolean => {
  return hasRole(user, "alpha") || hasRole(user, "beta");
};
